var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c("div", { staticClass: "mt-2" }, [
      _c("dl", [
        _c("dt", [_vm._v(_vm._s(_vm.$t("NAME")))]),
        _c("dd", [_vm._v(_vm._s(_vm.information.name))]),
        _c("dt", [_vm._v(_vm._s(_vm.$t("EMAIL")))]),
        _c("dd", [_vm._v(_vm._s(_vm.information.email))]),
        _vm.information.title
          ? _c("dt", [_vm._v(_vm._s(_vm.$t("TITLE")))])
          : _vm._e(),
        _vm.information.title
          ? _c("dd", [_vm._v(_vm._s(_vm.information.title))])
          : _vm._e(),
        _vm.information.department
          ? _c("dt", [_vm._v(_vm._s(_vm.$t("DEPARTMENT")))])
          : _vm._e(),
        _vm.information.department
          ? _c("dd", [_vm._v(_vm._s(_vm.information.department))])
          : _vm._e(),
        _c("dt", [_vm._v(_vm._s(_vm.$t("LAST_ACTIVITY")))]),
        _c("dd", [
          _vm._v(_vm._s(_vm.FormatDateTime(_vm.information.last_activity))),
        ]),
        _c("dt", [_vm._v(_vm._s(_vm.$t("CREATED")))]),
        _c("dd", [
          _vm._v(_vm._s(_vm.FormatDateTime(_vm.information.created_date))),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }