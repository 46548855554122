<template>
  <section class="mt-2">
    <b-overlay :show="loading" rounded="sm">

    <b-form-group
        label-cols-md="3"
        class="mb-2"
        :label="$t('SIGNATURE')"
      >
        <b-form-textarea
          v-model="signature"
          rows="5"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>

      <b-button variant="primary" class="btn-fill" @click="saveSubmit">{{
        $t("SAVE")
      }}</b-button>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["userinfo"],
  data() {
    return {
      loading: false,
      signature: this.userinfo.signature
    };
  },
  methods: {
    saveSubmit: function() {
      let self = this;
      this.loading = true;
      this.$http
        .post(this.user.hostname + "/user/edit/signature", {
          account_id: this.userinfo.id,
          signature: this.signature
        })
        .then(function() {
          self.$noty.info(self.$t("SAVED"));
          self.loading = false;
        })
        .catch(function() {
          self.loading = false;
        });
    }
  },
  computed: {
  },
  mounted: function() {},
};
</script>
<style></style>
