var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.userinfo
    ? _c("section", { staticClass: "mt-2" }, [
        _vm.methods
          ? _c(
              "div",
              [
                _c(
                  "b-overlay",
                  { attrs: { show: _vm.loading, rounded: "sm" } },
                  [
                    _vm.loadedMethods
                      ? _c(
                          "b-form-group",
                          {
                            attrs: {
                              "label-cols-md": "3",
                              label: _vm.$t("AUTH_METHODS.SELECT_METHOD"),
                            },
                          },
                          [
                            _c("b-form-select", {
                              attrs: { options: _vm.options },
                              on: { change: _vm.changedAuth },
                              model: {
                                value: _vm.selectedIndex,
                                callback: function ($$v) {
                                  _vm.selectedIndex = $$v
                                },
                                expression: "selectedIndex",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.showAddMore
                      ? _c(
                          "b-form-group",
                          {
                            key: _vm.editKey,
                            attrs: { "label-cols-md": "3", label: "" },
                          },
                          [
                            _vm.auth_type == "se-eid"
                              ? _c("Pnr", {
                                  attrs: { required: "" },
                                  on: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.saveBtn.apply(null, arguments)
                                    },
                                    valid: _vm.changeValid,
                                  },
                                  model: {
                                    value: _vm.DataDTO.pnr,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.DataDTO, "pnr", $$v)
                                    },
                                    expression: "DataDTO.pnr",
                                  },
                                })
                              : _vm._e(),
                            _vm.auth_type == "password"
                              ? _c("Password", {
                                  on: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.saveBtn.apply(null, arguments)
                                    },
                                    changed: _vm.changedData,
                                    valid: _vm.changeValid,
                                  },
                                })
                              : _vm._e(),
                            _vm.auth_type == "password-sms"
                              ? _c("PasswordSms", {
                                  attrs: {
                                    "edit-mode": "",
                                    "current-authentication-type":
                                      _vm.userinfo.authentication_type,
                                    DataDTO: _vm.DataDTO,
                                  },
                                  on: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.saveBtn.apply(null, arguments)
                                    },
                                    changed: _vm.changedData,
                                    valid: _vm.changeValid,
                                  },
                                })
                              : _vm._e(),
                            _vm.showIdentifier
                              ? _c("Identifier", {
                                  attrs: {
                                    placeholder: _vm.$t("UNIQUE_IDENTIFIER"),
                                  },
                                  on: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      return _vm.saveBtn.apply(null, arguments)
                                    },
                                    valid: _vm.changeValid,
                                  },
                                  model: {
                                    value: _vm.DataDTO.identifier,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.DataDTO, "identifier", $$v)
                                    },
                                    expression: "DataDTO.identifier",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-fill mt-2",
                        attrs: { disabled: !_vm.valid, variant: "primary" },
                        on: { click: _vm.saveBtn },
                      },
                      [_vm._v(_vm._s(_vm.$t("SAVE")))]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }