<template>
  <span v-if="user.information && user.ticket">
    <div class="persona">
      <div class="persona-image-area"><img alt="userlogo" :src="image" class="image" /></div>
      <div class="persona-details">
        <div class="primary-text">{{ user.information.name }}</div>     
      </div>
    </div>
    <div class="persona">
      <div class="persona-image-area">&nbsp;</div>
      <div class="persona-details">
        <div class="boldy-text mt-1">{{ user.information.organisation.name }}</div>     
        <div class="mt-1" v-if="user.information.department">{{ user.information.department }}</div>
        <div class="mt-1" v-if="user.information.department">{{ user.information.title }}</div>
        <div class="mt-1 hidden-not-mobile">{{ $t('ADMINLEVEL.ADMINRIGHTS.' + user.information.is_admin) }}</div>
        <div class="mt-1 hidden-not-mobile">{{ userLoa }}</div>
      </div>
    </div>
  </span>
</template>
<script>
export default {
  props: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    image(){
      return "/img/default-avatar.png";
    },
    userLoa() {
      if (this.user.information) {
        return this.$t("LOA.NAME") + " " + this.user.ticket.loa_level;
      } else {
        return "";
      }
    },
  },
  mounted(){
  }
};
</script>
<style></style>
