var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "b-form-group",
        { attrs: { label: _vm.useLabel, "label-cols-sm": _vm.cols } },
        [
          _c("div", { staticClass: "d-flex flex-row" }, [
            _c(
              "div",
              [
                _c("b-form-input", {
                  staticClass: "w-150px",
                  attrs: {
                    id:
                      _vm.startDate == null
                        ? "start-date-input"
                        : "end-date-input",
                    disabled: _vm.isDisabled,
                    type: "text",
                    placeholder: "YYYY-MM-DD",
                    state: _vm.IsValidDate == true ? null : false,
                    autocomplete: "off",
                    "aria-label": _vm.$t(
                      "NARR.PICK_DATE_TIMES.INPUT_TO_ENTER_START_DATE"
                    ),
                  },
                  on: { change: _vm.inputDateChanged },
                  model: {
                    value: _vm.date,
                    callback: function ($$v) {
                      _vm.date = $$v
                    },
                    expression: "date",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("b-form-datepicker", {
                  staticClass: "no-border",
                  attrs: {
                    "initial-date": "date",
                    "label-help": "",
                    "start-weekday": "1",
                    "button-only": "",
                    "hide-header": "",
                  },
                  on: { context: _vm.DatePickerChanged },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function () {
                        return [
                          _c("i", { staticClass: "fal fa-calendar-days" }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.datepicker,
                    callback: function ($$v) {
                      _vm.datepicker = $$v
                    },
                    expression: "datepicker",
                  },
                }),
              ],
              1
            ),
            _vm.useClock
              ? _c(
                  "div",
                  [
                    _c("b-form-input", {
                      staticClass: "w-100px",
                      attrs: {
                        id:
                          _vm.startDate == null
                            ? "start-time-input"
                            : "end-time-input",
                        type: "text",
                        disabled: _vm.isDisabled,
                        state: _vm.IsValidTime == true ? null : false,
                        placeholder: "HH:MM",
                        "aria-label": _vm.$t(
                          "NARR.PICK_DATE_TIMES.INPUT_TO_ENTER_START_TIME"
                        ),
                      },
                      on: { change: _vm.inputTimeChanged },
                      model: {
                        value: _vm.time,
                        callback: function ($$v) {
                          _vm.time = $$v
                        },
                        expression: "time",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.useClock
              ? _c(
                  "div",
                  [
                    _c("b-form-timepicker", {
                      ref: "TimePicker",
                      attrs: {
                        "button-only": "",
                        hour12: false,
                        "minutes-step": 15,
                        "hide-header": "",
                        "no-flip": "",
                        "show-seconds": false,
                        "aria-controls": "example-input",
                      },
                      on: { context: _vm.TimePickerChanged },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [_c("i", { staticClass: "fal fa-clock" })]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2003223324
                      ),
                      model: {
                        value: _vm.timepicker,
                        callback: function ($$v) {
                          _vm.timepicker = $$v
                        },
                        expression: "timepicker",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
      !_vm.IsValidFromTo
        ? _c("div", { staticClass: "error-text" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("ERROR.MEETING_START_AFTER_END")) +
                "\n    "
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }