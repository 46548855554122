var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-2" },
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, rounded: "sm" } },
        [
          _vm.user.information.is_admin > 0
            ? _c(
                "b-form-group",
                { attrs: { "label-cols-md": "3", label: _vm.$t("NAME") } },
                [
                  _c("b-form-input", {
                    attrs: {
                      trim: "",
                      required: "",
                      type: "text",
                      name: "name",
                    },
                    model: {
                      value: _vm.userinfo.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.userinfo, "name", $$v)
                      },
                      expression: "userinfo.name",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.user.information.is_admin > 0
            ? _c(
                "b-form-group",
                { attrs: { "label-cols-md": "3", label: _vm.$t("TITLE") } },
                [
                  _c("b-form-input", {
                    attrs: {
                      trim: "",
                      required: "",
                      type: "text",
                      name: "title",
                    },
                    model: {
                      value: _vm.userinfo.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.userinfo, "title", $$v)
                      },
                      expression: "userinfo.title",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.user.information.is_admin > 0
            ? _c(
                "b-form-group",
                {
                  attrs: { "label-cols-md": "3", label: _vm.$t("DEPARTMENT") },
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      trim: "",
                      required: "",
                      type: "text",
                      name: "department",
                    },
                    model: {
                      value: _vm.userinfo.department,
                      callback: function ($$v) {
                        _vm.$set(_vm.userinfo, "department", $$v)
                      },
                      expression: "userinfo.department",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-form-group",
            {
              attrs: { "label-cols-md": "3", label: _vm.$t("LANGUAGE.TITLE") },
            },
            [
              _c(
                "b-form-select",
                {
                  attrs: { name: "edit_user_select_language" },
                  model: {
                    value: _vm.userinfo.locale,
                    callback: function ($$v) {
                      _vm.$set(_vm.userinfo, "locale", $$v)
                    },
                    expression: "userinfo.locale",
                  },
                },
                _vm._l(_vm.sefos_locales, function (value, key) {
                  return _c(
                    "b-form-select-option",
                    {
                      key: "edit_user_select_language_" + key,
                      attrs: { value: key },
                    },
                    [_vm._v(_vm._s(value))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "btn-fill",
              attrs: { variant: "primary" },
              on: { click: _vm.saveSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("SAVE")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }