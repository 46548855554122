var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mt-2" },
    [
      _c(
        "b-overlay",
        { attrs: { show: _vm.loading, rounded: "sm" } },
        [
          _c(
            "b-form-group",
            {
              staticClass: "mb-2",
              attrs: { "label-cols-md": "3", label: _vm.$t("SIGNATURE") },
            },
            [
              _c("b-form-textarea", {
                attrs: { rows: "5", "max-rows": "6" },
                model: {
                  value: _vm.signature,
                  callback: function ($$v) {
                    _vm.signature = $$v
                  },
                  expression: "signature",
                },
              }),
            ],
            1
          ),
          _c(
            "b-button",
            {
              staticClass: "btn-fill",
              attrs: { variant: "primary" },
              on: { click: _vm.saveSubmit },
            },
            [_vm._v(_vm._s(_vm.$t("SAVE")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }