var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user.information && _vm.user.ticket
    ? _c("span", [
        _c("div", { staticClass: "persona" }, [
          _c("div", { staticClass: "persona-image-area" }, [
            _c("img", {
              staticClass: "image",
              attrs: { alt: "userlogo", src: _vm.image },
            }),
          ]),
          _c("div", { staticClass: "persona-details" }, [
            _c("div", { staticClass: "primary-text" }, [
              _vm._v(_vm._s(_vm.user.information.name)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "persona" }, [
          _c("div", { staticClass: "persona-image-area" }, [_vm._v(" ")]),
          _c("div", { staticClass: "persona-details" }, [
            _c("div", { staticClass: "boldy-text mt-1" }, [
              _vm._v(_vm._s(_vm.user.information.organisation.name)),
            ]),
            _vm.user.information.department
              ? _c("div", { staticClass: "mt-1" }, [
                  _vm._v(_vm._s(_vm.user.information.department)),
                ])
              : _vm._e(),
            _vm.user.information.department
              ? _c("div", { staticClass: "mt-1" }, [
                  _vm._v(_vm._s(_vm.user.information.title)),
                ])
              : _vm._e(),
            _c("div", { staticClass: "mt-1 hidden-not-mobile" }, [
              _vm._v(
                _vm._s(
                  _vm.$t(
                    "ADMINLEVEL.ADMINRIGHTS." + _vm.user.information.is_admin
                  )
                )
              ),
            ]),
            _c("div", { staticClass: "mt-1 hidden-not-mobile" }, [
              _vm._v(_vm._s(_vm.userLoa)),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }