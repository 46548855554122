<template>
  <section class="edit-oof mt-2">
    <b-overlay :show="loading" rounded="sm">
      <div class="edit-oof-info mt-1">
        {{ $t('OUT_OF_THE_OFFICE.AUTOMATIC_REPLY_INFORMATION_TEXT') }}
      </div>

      <div class="edit-oof-group mt-3 d-flex align-items-center">
        <b-form-checkbox
          switch
          v-model="out_of_office_enabled"
          @change="handleOutOfOfficeChange"
          :aria-label="$t('NARR.OUT_OF_OFFICE.SWITCH_TO_ACTIVATE_AUTO_REPLY')"
        ></b-form-checkbox>
        <span class="edit-oof-label ml-2">{{ $t('OUT_OF_THE_OFFICE.ACTIVATE_AUTOMATIC_REPLIES') }}</span>
      </div>

      <div class="edit-oof-group mt-3 d-flex align-items-center">
        <b-form-checkbox
          switch
          v-model="use_start_end_date"
          :disabled="!out_of_office_enabled"
          :aria-label="$t('NARR.OUT_OF_OFFICE.SWITCH_TO_SPECIFY_DATE_TIME_FOR_AUTO_REPLY')"
        ></b-form-checkbox>
        <span class="edit-oof-label ml-2">{{ $t('OUT_OF_THE_OFFICE.SEND_REPLIES_UNDER_SPECIFIC_TIME') }}</span>
      </div>

      <PickDate
      v-model="startDateTime"
      :use-label="$t('FROM')"
      use-clock
      :isDisabled="!use_start_end_date"
      @valid="(n) => (validStart = n)"></PickDate>

      <PickDate
      v-model="endDateTime"
      :start-date="startDateTime"
      :use-label="$t('FROM')"
      :isDisabled="!use_start_end_date"
      use-clock
      @valid="(n) => (validEnd = n)"></PickDate>

      <div v-if="out_of_office_enabled" class="mt-2">
        <b-form-group label-cols-md="3" class="edit-oof-group mb-2" :label="$t('MESSAGE')">
          <b-form-textarea
            v-model="out_of_office_message"
            rows="5"
            max-rows="6"
            :aria-label="$t('NARR.OUT_OF_OFFICE.INPUT_TO_ENTER_AUTO_REPLY_MESSAGE')"
          ></b-form-textarea>
        </b-form-group>
      </div>

      <b-button variant="primary" class="btn-fill" @click="saveSubmit" :disabled="!isFormValid">{{ $t('SAVE') }}</b-button>
    </b-overlay>
  </section>
</template>

<script>
import PickDate from "../Input/PickDate";
export default {
  props: ['userinfo'],
  components: {
    PickDate
  },
  data() {
    return {
      validEnd: true,
      validStart: true,
      loading: false,
      out_of_office_enabled: this.userinfo.out_of_office_enabled,
      out_of_office_message: this.userinfo.out_of_office_message,
      startDateTime: this.userinfo.out_of_office_start_date,
      endDateTime: this.userinfo.out_of_office_end_date,
      use_start_end_date: this.userinfo.out_of_office_enabled,
      isFormValid: true,
    };
  },
  methods: {
    handleOutOfOfficeChange(newVal) {
      this.use_start_end_date = newVal;
    },
    async saveSubmit() {      
      this.loading = true;
      try {
       await this.$http
        .post(`${this.user.hostname}/user/edit/out-of-office`, {
          account_id: this.userinfo.id,
          out_of_office_enabled: this.out_of_office_enabled,
          out_of_office_message: this.out_of_office_message,
          out_of_office_start_date: this.startDateTime,
          out_of_office_end_date: this.endDateTime
        });
        this.$noty.info(this.$t('SAVED'));
        await this.$store.dispatch("user/fetchUser");
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.startDateTime = this.userinfo.out_of_office_start_date;
    this.endDateTime = this.userinfo.out_of_office_end_date;
  }
};
</script>

<style scoped>
.edit-oof-info {
  margin-bottom: 10px;
}
.edit-oof-group {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.edit-oof-label {
  margin-left: 8px;
}
</style>
