<template>
  <section class="mt-2">
    <b-overlay :show="loading" rounded="sm">

      <b-form-group label-cols-md="3" :label="$t('NAME')" v-if="user.information.is_admin > 0">
        <b-form-input trim
          required
          type="text"
          v-model="userinfo.name"
          name="name"
        ></b-form-input>
      </b-form-group>
      
      <b-form-group label-cols-md="3" :label="$t('TITLE')" v-if="user.information.is_admin > 0">
        <b-form-input trim
          required
          type="text"
          v-model="userinfo.title"
          name="title"
        ></b-form-input>
      </b-form-group>
      
      <b-form-group label-cols-md="3" :label="$t('DEPARTMENT')" v-if="user.information.is_admin > 0">
        <b-form-input trim
          required
          type="text"
          v-model="userinfo.department"
          name="department"
        ></b-form-input>
      </b-form-group>

      <b-form-group label-cols-md="3" :label="$t('LANGUAGE.TITLE')">
        <b-form-select  name="edit_user_select_language" v-model="userinfo.locale">
          <b-form-select-option
            v-for="(value, key) in sefos_locales"
            :key="'edit_user_select_language_' + key"
            :value="key"
            >{{ value }}</b-form-select-option
          >
        </b-form-select>
      </b-form-group>


      <b-button variant="primary" class="btn-fill" @click="saveSubmit">{{
        $t("SAVE")
      }}</b-button>
    </b-overlay>
  </section>
</template>
<script>
export default {
  props: ["userinfo"],
  data() {
    return {
      loading: false,
      adminOptions: [
        { value: 0, text: this.$t("ADMINLEVEL.0") },
        { value: 1, text: this.$t("ADMINLEVEL.1") },
        { value: 2, text: this.$t("ADMINLEVEL.2") },
      ],
    };
  },
  methods: {
    saveSubmit: function() {
      let self = this;
      this.loading = true;
      this.$http
        .post(this.user.hostname + "/user/edit", this.userinfo)
        .then(function(result) {
          self.$noty.info(self.$t("SAVED"));
          self.$emit("updatedUser", result.data);
          self.loading = false;
        })
        .catch(function() {
          self.loading = false;
        });
    }
  },
  computed: {
  },
  mounted: function() {},
};
</script>
<style></style>
