<template>
  <section>
    <div class="mt-2">

      <dl>
        <dt>{{ $t("NAME") }}</dt>
        <dd>{{ information.name }}</dd>
        <dt>{{ $t("EMAIL") }}</dt>
        <dd>{{ information.email }}</dd>
        <dt v-if="information.title">{{ $t("TITLE") }}</dt>
        <dd v-if="information.title">{{ information.title }}</dd>
        <dt v-if="information.department">{{ $t("DEPARTMENT") }}</dt>
        <dd v-if="information.department">{{ information.department }}</dd>
        <dt>{{ $t('LAST_ACTIVITY') }}</dt>
        <dd>{{ FormatDateTime(information.last_activity) }}</dd>
        <dt>{{ $t('CREATED') }}</dt>
        <dd>{{ FormatDateTime(information.created_date) }}</dd>
      </dl>

    </div>

  </section>
</template>
<script>
export default {
  props: ["userinfo"],
  data() {
    return {
      information: this.userinfo
    };
  },
  methods: {    
    showInvite: function(state) {
      return (state === 0) ?  this.$t("NO") : this.$t("YES");
    },
    showValidated: function(state) {
      return (state === 0) ? this.$t("NO") : this.$t("YES");
    },
    showAdmin: function(state) {
      return (state === 0) ? this.$t("NO") : this.$t("YES");
    }
  },
  mounted: function() {
  }
};
</script>
<style>
</style>